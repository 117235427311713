import React, {useEffect, useRef, useState} from "react"
import Header from "../header/Header";
import Nav from "../nav/Nav";
import About from "../about/About";
import Experience from "../experience/Experience";
import Services from "../services/Services";
import Portfolio from "../portfolio/Portfolio";
import WorkProcess from "../workProcess/WorkProcess";
import Blog from "../blog/Blog";
import Faq from "../faq/Faq";
import Contact from "../contact/Contact";

const MainPage = ({activeNav, setActiveNav}) => {
    const [observer, setObserver] = useState()
    const aboutRef = useRef(null)
    const servicesRef = useRef(null)
    const portfolioRef = useRef(null)
    const stepsRef = useRef(null)
    const blogRef = useRef(null)
    const faqRef = useRef(null)

    const observerRefs = [aboutRef, servicesRef, portfolioRef, stepsRef, blogRef, faqRef]

    const onVisible = (entry) => {
        switch (entry.target.id) {
            case 'header':
                setActiveNav('#')
                break
            case 'about_me':
                setActiveNav('#about')
                break
            case 'services':
                setActiveNav('#services')
                break
            case 'portfolio':
                setActiveNav('#portfolio')
                break
            case 'steps':
                setActiveNav('#steps')
                break
            case 'blog_swiper':
                setActiveNav('#blog')
                break
            case 'faq':
                setActiveNav('#faq')
                break
            case 'contact':
                setActiveNav('#contact')
                break
            default:
                return
        }
    }

    useEffect(() => {
        const observer = new IntersectionObserver(entries => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    onVisible(entry)
                }
            })
            }, {threshold: 0.4})
        setObserver(observer)

        return () => {
            observer.disconnect()
        }
    }, [])

    return (
        <>
            <Header setActiveNav={setActiveNav} observer={observer}/>
            <Nav activeNav={activeNav} setActiveNav={setActiveNav} observer={observer} observerRefs={observerRefs}/>
            <About observer={observer} sectionRef={aboutRef}/>
            <Experience/>
            <Services observer={observer} sectionRef={servicesRef}/>
            <Portfolio observer={observer} sectionRef={portfolioRef}/>
            {/*Component temporary disabled*/}
            {/*<Testimonials/>*/}
            <WorkProcess observer={observer} sectionRef={stepsRef}/>
            <Blog observer={observer} sectionRef={blogRef}/>
            <Faq observer={observer} sectionRef={faqRef}/>
            <Contact observer={observer}/>
        </>
    )
}

export default MainPage