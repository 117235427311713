import React from 'react'
import './footer.css'
import {Link, useLocation} from "react-router-dom"

const Footer = ({setActiveNav}) => {
    let locationLength = useLocation().pathname.split('/').length

    return (
        <footer>
            {locationLength === 2
                ? <a href='#' onClick={() => setActiveNav('#')} className='footer_logo'>Yana Trofimova</a>
                : <Link to='/' onClick={() => setActiveNav('#')} className='footer_logo'>Yana Trofimova</Link>
            }
            <div className='footer_copyright'>
                <small>Design and sketching</small>
                <br/>
                <small>&copy; Yana Trofimova</small>
            </div>
        </footer>
    );
};

export default Footer;
