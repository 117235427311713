import React, {useState, useEffect} from 'react'
import './navbar.css'
import {Link} from "react-router-dom"
import {FaBars} from 'react-icons/fa'
import {BiHome} from 'react-icons/bi'

const Navbar = () => {
    const [showLinks, setShowLinks] = useState(false)

    const handleTouchClick = (event) => {
        if (showLinks && !event.target.closest('ul') && !event.target.closest('button')) {
            setShowLinks(false)
        }
    }

    const handleScroll = () => {
        if (showLinks) {
            setShowLinks(false)
        }
    }

    useEffect(() => {
        document.addEventListener('touchstart', handleTouchClick)
        document.addEventListener('mousedown', handleTouchClick)
        document.addEventListener('scroll', handleScroll)
        return () => {
            document.removeEventListener('touchstart', handleTouchClick)
            document.removeEventListener('mousedown', handleTouchClick)
            document.removeEventListener('scroll', handleScroll)
        }
    }, [showLinks])

    return (
        <>
            <div className='top_nav'>
                <div className='nav_left_side'>
                    <Link to="/" className='nav_icon'>
                        <BiHome/>
                    </Link>
                </div>
                <div className='nav_right_side'>
                    <ul className={`links ${showLinks ? 'hidden' : ''}`}>
                        <li className='link' onClick={() => setShowLinks(false)}>
                            <Link to="/" className='menu-item'>Home</Link>
                        </li>
                        <li className='link' onClick={() => setShowLinks(false)}>
                            <Link to="/blog/overview" className='menu-item'>Blog</Link>
                        </li>
                        <li className='link' onClick={() => setShowLinks(false)}>
                            <Link to="/sketch/price" className='menu-item'>Price list</Link>
                        </li>
                        <li className='link' onClick={() => setShowLinks(false)}>
                            <Link to="/sketch/contact" className='menu-item'>Contacts</Link>
                        </li>
                    </ul>
                    <button className='nav_btn' onClick={() => setShowLinks(!showLinks)}>
                        <FaBars className='nav_links_icon'/></button>
                </div>
            </div>
        </>
    )
}

export default Navbar