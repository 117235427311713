import React from 'react'

const Arrow = ({ isRotated }) => (
    <svg
        className={`arrow ${isRotated ? 'rotated' : ''}`}
        width="12"
        height="15"
        viewBox="0 0 42 25"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path d="M3 3L21 21L39 3" stroke="black" strokeWidth="7" strokeLinecap="round"/>
    </svg>
)

export default Arrow