import React, {useEffect} from 'react'
import './blog.css'
import {Link} from "react-router-dom"

import { Pagination, Navigation } from "swiper"
import { Swiper, SwiperSlide } from 'swiper/react'
import {RxDoubleArrowRight} from 'react-icons/rx'
// Import Swiper styles
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/navigation'
import {blogData} from "../../Data"
import Aos from "aos"
import 'aos/dist/aos.css'

const Blog = ({observer, sectionRef}) => {
    useEffect(() => {
        Aos.init({duration:2000})
    }, [])

    useEffect(() => {
        if (observer) {
            observer.observe(sectionRef.current)
        }
    }, [sectionRef, observer])

    return (
        <section id='blog'>
            <div data-aos='fade-up' data-aos-duration='2000' className='blog_header'>
                <h2>Blog</h2>
                <h1>My recent posts</h1>
            </div>
            <div data-aos='zoom-in' data-aos-duration='2000' className='container blog_container' id='blog_swiper' ref={sectionRef}>
                <Swiper
                    style={{
                        "--swiper-navigation-color": "var(--color-bg-footer)",
                        "--swiper-pagination-color": "var(--color-bg-footer)",
                    }}
                    slidesPerView={3}
                    spaceBetween={30}
                    slidesPerGroup={3}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="bigSwiper"
                >
                    {
                        blogData.map((blog, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <img className='blog_img' src={blog.img} alt='sketch'/>
                                    <div className="title"><h3>{blog.title}</h3></div>
                                    <div className="text">
                                        <span>{blog.description}</span>
                                        <Link to={blog.path} className='blog_link'>Read more&nbsp;
                                            <RxDoubleArrowRight className='icon'/>
                                        </Link>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>

                <Swiper
                    style={{
                        "--swiper-navigation-color": "var(--color-bg-footer)",
                        "--swiper-pagination-color": "var(--color-bg-footer)",
                    }}
                    slidesPerView={1}
                    spaceBetween={40}
                    loop={true}
                    loopFillGroupWithBlank={true}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    modules={[Pagination, Navigation]}
                    className="smallSwiper"
                >
                    {
                        blogData.map((blog, index) => {
                            return (
                                <SwiperSlide key={index}>
                                    <img className='blog_img' src={blog.img} alt='sketch'/>
                                    <div className="title"><h3>{blog.title}</h3></div>
                                    <div className="text">
                                        <span>{blog.description}</span>
                                        <Link to={blog.path} className='blog_link'>Read more&nbsp;
                                            <RxDoubleArrowRight className='icon'/>
                                        </Link>
                                    </div>
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
        </section>
    )
}

export default Blog