import React, {useEffect} from 'react'
import './experience.css'
import {BsFillCheckCircleFill} from 'react-icons/bs'
import Aos from "aos"
import 'aos/dist/aos.css'

const Experience = () => {
    useEffect(() => {
        Aos.init({duration:2000})
    }, [])

    return (
        <section id='experience'>
            <div data-aos='fade-up' data-aos-duration='2000' className='experience_header'>
                <h2>What sketches I draw</h2>
                <h1>Services</h1>
            </div>
            <div data-aos='zoom-in' data-aos-duration='2000' className='container experience_container'>
                <div className='experience_content'>
                    <article className='experience_details'>
                        <BsFillCheckCircleFill className='experience_details_icon'/>
                        <div>
                            <h4>Interior sketch</h4>
                        </div>
                    </article>
                    <article className='experience_details'>
                        <BsFillCheckCircleFill className='experience_details_icon'/>
                        <div>
                            <h4>Exterior sketch</h4>
                        </div>
                    </article>
                    <article className='experience_details'>
                        <BsFillCheckCircleFill className='experience_details_icon'/>
                        <div>
                            <h4>2d and 3d floor plans</h4>
                        </div>
                    </article>
                    <article className='experience_details'>
                        <BsFillCheckCircleFill className='experience_details_icon'/>
                        <div>
                            <h4>Architectural sketch</h4>
                        </div>
                    </article>
                    <article className='experience_details'>
                        <BsFillCheckCircleFill className='experience_details_icon'/>
                        <div>
                            <h4>Landscape</h4>
                        </div>
                    </article>
                    <article className='experience_details'>
                        <BsFillCheckCircleFill className='experience_details_icon'/>
                        <div>
                            <h4>Event sketch</h4>
                        </div>
                    </article>
                </div>
            </div>
        </section>
    );
};

export default Experience;
