import React from 'react'
import './contactPage.css'

import Contact from "../contact/Contact";

const ContactPage = () => {

    return (
        <>
            <div className='contact_page'>
                <Contact/>
            </div>
        </>
    )
}

export default ContactPage