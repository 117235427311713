import React, {useState} from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import Footer from "./components/footer/Footer";
import BlogPage from "./components/blogPage/BlogPage";
import MainPage from "./components/mainPage/MainPage";
import ContactPage from "./components/contactPage/ContactPage";
import PricePage from "./components/pricelistPage/PricePage";
import BlogOverviewPage from "./components/blogOverviewPage/BlogOverviewPage";
import Navbar from "./components/navbar/Navbar";

const App = () => {
    const [activeNav, setActiveNav] = useState('#');

    return (
        <>
            <BrowserRouter>
                <Navbar/>
                <Routes>
                    <Route path="/" element={<MainPage activeNav={activeNav} setActiveNav={setActiveNav}/>}/>
                    <Route path="/sketch/price" element={<PricePage />}/>
                    <Route path="/sketch/contact" element={<ContactPage />}/>
                    <Route path="/blog/overview" element={<BlogOverviewPage />}/>
                    <Route path="/blog/:post" element={<BlogPage />}/>
                </Routes>
                <Footer setActiveNav={setActiveNav}/>
            </BrowserRouter>
        </>
    );
};

export default App;
