import React, {useEffect, useState} from 'react'
import './faq.css'
import {faqData} from "../../Data"
import Arrow from "./Arrow"
import Aos from "aos"
import 'aos/dist/aos.css'

const Faq = ({observer, sectionRef}) => {
    const [active, setActive] = useState(false)

    useEffect(() => {
        Aos.init({duration:2000})
    }, [])

    useEffect(() => {
        if (observer) {
            observer.observe(sectionRef.current)
        }
    }, [sectionRef, observer])

    const handleClick = (faq) => {
        faq.active = !faq.active
        setActive(!active)
    }

    return (
        <section id='faq' ref={sectionRef}>
            <div data-aos='fade-up' data-aos-duration='2000' className='faq_header'>
                <h2>FAQs</h2>
                <h1>Find the answers</h1>
            </div>
            <div data-aos='zoom-in' data-aos-duration='2000' className='container faq_container'>
                {
                    faqData.map((faq, index) => {
                        return (
                            <div key={index} className={`faq ${faq.active ? 'active' : ''}`}
                                 onClick={() => handleClick(faq)}>
                                <div className='question'>
                                    <h3>{faq.question}</h3>
                                    <div className='arrow_icon'>
                                        <Arrow isRotated={faq.active}/>
                                    </div>
                                </div>
                                <div className='answer'>
                                    <p>{faq.answer}</p>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </section>
    )
}

export default Faq