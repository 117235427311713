import React from 'react'

const Step = ({step, setCurrentStep, index, activeStep, setActiveStep}) => {

    const handleMouseEnter = (currentStep) => {
        setCurrentStep(currentStep)
        setActiveStep(index)
    }

    return (
        <div className='step_block'>
            <div className='circle_wrapper' onMouseEnter={() => handleMouseEnter(step)}>
                <div className={`circle ${activeStep === index ? 'active' : ''}`}>{index + 1}</div>
            </div>
            {/*<span className='step_title'>{step.title}</span>*/}
        </div>
    )
}

export default Step