import React from 'react';
import './nav.css';
import {BiHome, BiUserCircle, BiMessageEdit, BiHelpCircle} from 'react-icons/bi';
import {MdOutlineArticle} from 'react-icons/md';
import {AiOutlineEuroCircle, AiOutlinePicture} from 'react-icons/ai';
import {GrSteps} from 'react-icons/gr';

const Nav = ({activeNav, setActiveNav, observer, observerRefs}) => {

    const disableObserve = () => {
        observerRefs.filter(ref => ref != null)
            .forEach(ref => observer.unobserve(ref.current))
    }

    const enableObserve = () => {
        observerRefs.filter(ref => ref != null)
            .reverse()
            .forEach(ref => observer.observe(ref.current))
    }

    const setActive = (sectionId) => {
        disableObserve()
        setActiveNav(sectionId)
        setTimeout(() => {
            enableObserve()
        }, 700)
    }

    return (
        <nav>
            <a href='#' onClick={() => setActive('#')} className={activeNav === '#' ? 'active' : ''}><BiHome/></a>
            <a href='#about' onClick={() => setActive('#about')} className={activeNav === '#about' ? 'active' : ''}><BiUserCircle/></a>
            <a href='#services' onClick={() => setActive('#services')} className={activeNav === '#services' ? 'active' : ''}><AiOutlineEuroCircle/></a>
            <a href='#portfolio' onClick={() => setActive('#portfolio')} className={activeNav === '#portfolio' ? 'active' : ''}><AiOutlinePicture/></a>
            <a href='#steps' onClick={() => setActive('#steps')} className={activeNav === '#steps' ? 'active' : ''}><GrSteps/></a>
            <a href='#blog' onClick={() => setActive('#blog')} className={activeNav === '#blog' ? 'active' : ''}><MdOutlineArticle/></a>
            <a href='#faq' onClick={() => setActive('#faq')} className={activeNav === '#faq' ? 'active' : ''}><BiHelpCircle/></a>
            <a href='#contact' onClick={() => setActive('#contact')} className={activeNav === '#contact' ? 'active' : ''}><BiMessageEdit/></a>
        </nav>
    );
};

export default Nav;
