import React, {useEffect, useRef} from 'react'
import './contact.css'
import {MdOutlineMailOutline} from 'react-icons/md'
import {FaTelegramPlane} from 'react-icons/fa'
import {BsInstagram} from 'react-icons/bs'
import emailjs from 'emailjs-com'
import Filter from 'bad-words'
import Aos from "aos"
import 'aos/dist/aos.css'

const regExp = /[a-zA-Z]+/g

const Contact = ({observer}) => {
    const sectionRef = useRef(null)
    const form = useRef()
    const filter = new Filter()
    filter.addWords('suck', 'silly', 'dumb')

    useEffect(() => {
        Aos.init({duration:2000})
    }, [])

    useEffect(() => {
        if (observer) {
            observer.observe(sectionRef.current)
        }
    }, [sectionRef, observer])

    const sendEmail = (e) => {
        e.preventDefault();

        if (e.target.message.value.length > 20 && !filter.isProfane(e.target.message.value)) {
            console.log('ok to send')
            emailjs.sendForm('service_psabp8k', 'template_bdps3l3', form.current, 'DjbuLLSBCv_PY31x9')
                .then((result) => {
                    console.log(result.text)
                    alert("Your message is successfully sent! Thank you!")
                }, (error) => {
                    console.log(error.text)
                    alert(error.text)
                });
        }

        e.target.reset()
    };

    return (
        <section id='contact' ref={sectionRef}>
            <div data-aos='fade-up' data-aos-duration='2000' className='contact_header'>
                <h2>Get In Touch</h2>
                <h1>Contact Me</h1>
            </div>
            <div data-aos='zoom-in' data-aos-duration='2000' className='container contact_container'>
                <div className='contact_options'>
                    <a href='mailto:artdesignyana@gmail.com' target='_blank' rel="noreferrer">
                        <article className='contact_option'>
                            <MdOutlineMailOutline className='contact_option_icon'/>
                            <h4>Send Email</h4>
                            <h4>artdesignyana@gmail.com</h4>
                        </article>
                    </a>
                    <a href='https://telegram.me/artdesign_yana' target='_blank' rel="noreferrer">
                        <article className='contact_option'>
                            <FaTelegramPlane className='contact_option_icon'/>
                            <h4>Telegram</h4>
                            <h4>Send a message</h4>
                        </article>
                    </a>
                    <a href='https://instagram.com/artdesign_yana' target='_blank' rel="noreferrer">
                        <article className='contact_option'>
                            <BsInstagram className='contact_option_icon'/>
                            <h4>Instagram</h4>
                        </article>
                    </a>
                </div>
                <form ref={form} onSubmit={sendEmail}>
                    <input type='text' name='name' placeholder='Your Full Name' required/>
                    <input type='email' name='email' placeholder='Your email' required/>
                    <textarea name='message' rows='10' placeholder='Info about your project' required/>
                    <button type='submit' className={'btn btn-primary'}><b>Send Message</b></button>
                </form>
            </div>
        </section>
    );
};

export default Contact;
