import Sketch1 from "./images/sketch/image-27-06-22-1.jpg";
import Sketch2 from "./images/sketch/image-27-06-22-2.jpg";
import Sketch3 from "./images/sketch/image-27-06-22-3.jpg";
import Sketch4 from "./images/sketch/image-27-06-22-4.jpg";
import Sketch5 from "./images/sketch/image-27-06-22-5.jpg";
import Sketch6 from "./images/sketch/image-27-06-22-6.jpg";
import Sketch7 from "./images/sketch/image-27-06-22-7.jpg";
import Sketch8 from "./images/sketch/image-27-06-22-8.jpg";
import Sketch9 from "./images/sketch/image-27-06-22-9.jpg";

import BlogImg1 from "./images/blog/blog-img-1.jpg";
import BlogImg2 from "./images/blog/blog-img-2.jpg";
import BlogImg3 from "./images/blog/blog-img-3.jpg";
import BlogImg4 from "./images/blog/blog-img-4.jpg";
import BlogImg5 from "./images/blog/blog-img-5.jpg";
import BlogImg6 from "./images/blog/blog-img-6.jpg";
import BlogImg7 from "./images/blog/blog-img-7.jpg";
import BlogImg8 from "./images/blog/blog-img-8.jpg";

import PriceOverall from "./images/price/price-overall.jpg";
import Price2D from "./images/price/price-2D.jpg";
import Price2DWithLand from "./images/price/price-2D_with_landscape.jpg";
import Price3D from "./images/price/price-3D.jpg";
import PriceArchitect from "./images/price/price-architect.jpg";
import PriceCommercial from "./images/price/price-commercial.jpg";
import PriceEvent from "./images/price/price-event.jpg";
import PriceInteriorModern from "./images/price/price-interior-modern.jpg";
import PriceInteriorClassic from "./images/price/price-interior-classic.jpg";
import PriceLandscape from "./images/price/price-landscape.jpg";
import PriceNightView from "./images/price/price-night_view.jpg";

export const aboutData = [
    {
        section: 'Experience',
        description: '1+ Years Working And Study'
    },
    {
        section: 'Clients',
        description: '2+ Worldwide'
    },
    {
        section: 'Projects',
        description: '5+ Completed'
    }
]

export const designServiceData = [
    {
        name: 'Floor plan (2-3 options in AutoCAD + the final variant in color)',
        price: 'from 80 €/room'
    },
    {
        name: 'Interior Design online (Floor plan, design concept, style, color, furniture and decor, visualization)',
        price: 'from 250 €/room'
    },
    {
        name: 'Interior Design in Belgrade (Provide different options. Please contact me for more information)',
        price: '€ depends on case'
    }
]

export const sketchServiceData = [
    {
        name: 'Interior sketch (Living room, kitchen, bedroom, bathroom and etc.)',
        price: 'from 100 €'
    },
    {
        name: 'Commercial sketch (Restaurants, cafe, office, etc.)',
        price: 'from 150 €'
    },
    {
        name: 'Event sketch (Wedding, celebration)',
        price: 'from 120 €'
    },
    {
        name: 'Architectural sketch (Exterior, architecture)',
        price: 'from 150 €'
    },
    {
        name: '2D floor plan (furniture layout in color)',
        price: 'from 100 €'
    },
    {
        name: '3D floor plan (furniture layout in color)',
        price: 'from 150 €'
    },
    {
        name: 'Landscape',
        price: 'from 120 €'
    },
    {
        name: 'Night view (additional to day view)',
        price: 'from 50 €'
    },
]

export const sketchProjectData = [
    {
        designImg: Sketch1,
        projectName: 'Interior sketch',
        description: 'Description of Project'
    },
    {
        designImg: Sketch2,
        projectName: 'Interior sketch',
        description: 'Description of Project'
    },
    {
        designImg: Sketch3,
        projectName: 'Floor Plan',
        description: 'Description of Project'
    },
    {
        designImg: Sketch4,
        projectName: 'Floor Plan + Landscape',
        description: 'Description of Project'
    },
    {
        designImg: Sketch5,
        projectName: 'Architectural sketch',
        description: 'Description of Project'
    },
    {
        designImg: Sketch6,
        projectName: 'Architectural sketch',
        description: 'Description of Project'
    },
    {
        designImg: Sketch7,
        projectName: 'Event sketch',
        description: 'Description of Project'
    },
    {
        designImg: Sketch8,
        projectName: 'Commercial sketch: restaurant',
        description: 'Description of Project'
    },
    {
        designImg: Sketch9,
        projectName: 'Commercial sketch: office',
        description: 'Description of Project'
    }
]

export const faqData = [
    {
        question: 'What’s the price of a sketch?',
        answer: 'The up-to-date prices you can find in a Services section above or contact me and I’ll send you my offer with all information about my services, terms and price.',
        active: false
    },
    {
        question: 'Which payment method is convenient for you?',
        answer: 'Please note that the only 2 ways to pay the order - PayPal or WesternUnion. The SWIFT or other bank transfers are not acceptable.',
        active: false
    },
    {
        question: 'Can I send money with PayPal?',
        answer: 'Yes. Just send me your account email or username and I will send you an invoice.',
        active: false
    },
    {
        question: 'Can I send money with WesternUnion?',
        answer: 'Yes. My current location is Serbia. Just share tracking number (MTCN) and let me know that the cash is ready for pickup.',
        active: false
    },
    {
        question: 'How long it takes to draw a sketch?',
        answer: 'It takes maximally 4 working days for a sketch and depends on a complexity of your task. Reviews and other approval activities are not included in that time.',
        active: false
    },
    {
        question: 'Can you draw a sketch ASAP?',
        answer: 'ASAP deadline is no less then 1 day. It is possible if you have clear technical task with materials and plans. 100% prepayment is required.',
        active: false
    },
    {
        question: 'I want to renovate my house/facade or build a new house. Could you help me?',
        answer: 'I am a digital artist. I draw sketches of buildings only according to your clear technical task. But I can not do architects work like design plans and specifications for a project. ' +
            'Information about work process you can find in Steps section above.',
        active: false
    },
    {
        question: 'I don’t have 3d model but I want an exterior/interior sketch from you. Could you help me?',
        answer: 'Yes, of course. I always build a simple and not detailed 3d model in ArchiCAD for convenient and fast work.',
        active: false
    },
    {
        question: 'Could you develop a project if I don’t have any project details, plans or materials?',
        answer: 'Unfortunately no, a clear technical task is required. I can’t pick up furniture, decor, covers and materials for your project.',
        active: false
    },
    {
        question: 'Do you have master classes or courses?',
        answer: 'I don’t have any courses now, but if you’re designer or architect I can help you to save your time with project visualisation.',
        active: false
    },
    {
        question: 'What software are you using for a sketch?',
        answer: 'I use Procreate app which runs on Apple devices only.',
        active: false
    },
    {
        question: 'Which tablet are you using for a sketch?',
        answer: 'My tablet is IPad Pro 2021, 11 inch.',
        active: false
    },
]

export const stepsData = [
    {
        title: 'Step 1 - Initial Contact',
        description: 'Contact me using any convenient way (you can find the \'Contact Me\' section at the bottom of this page) and provide a brief overview of your project and the specific sketches you require.',
        active: false
    },
    {
        title: 'Step 2 - Draft Proposal',
        description: 'I will respond with an offer, which includes information about my terms and an approximate cost estimate. I will also provide an estimate of the project timeline and request any additional technical details that may be necessary.',
        active: false
    },
    {
        title: 'Step 3 - Technical Task Submission',
        description: 'You can send me a technical task via email. I will carefully review it and, if necessary, prepare a list of clarifying questions regarding the project.',
        active: false
    },
    {
        title: 'Step 4 - Final Offer',
        description: 'We discuss the final project price and timeline.\n' +
            ' Please note that the time required for task approval and the review of technical details is not included in the project deadline.',
        active: false
    },
    {
        title: 'Step 5 - Payment',
        description: 'To start the project, a 100% prepayment is required. You can make the payment using Western Union or PayPal.',
        active: false
    },
    {
        title: 'Step 6 - Optional Initial Rendering',
        description: 'If needed, I will create a basic 3D model in ArchiCAD. We will collaborate to approve the specific angles for the project.\n' +
            'It\'s crucial to note that once these angles are approved, they cannot be changed.',
        active: false
    },
    {
        title: 'Step 7 - Drawing the Sketches',
        description: 'Begin the drawing process. The average time of drawing each sketch depends on its complexity and typically takes around 2 days.',
        active: false
    },
    {
        title: 'Step 8 - Sketch Approval',
        description: 'Once the sketch is complete, I will send it to you for approval. You have the option to request changes at this stage, such as color corrections, adding/removing decor or adjusting lightning.\n' +
            'Detailed information about possible edits can be found in the initial offer, and feel free to ask any questions you may have.',
        active: false
    },
]

export const blogData = [
    {
        img: BlogImg1,
        title: 'About sketch',
        description: 'I know a great way to present your project faster and make it memorable!\n' +
            'Sketch is a quick, spectacular and vivid way to visualize your idea or project!\n' +
            'Sketches can be different types:\n' +
            '○ Interior sketch\n' +
            '○ Landscape\n' +
            '○ 2d and 3d plans for interior and landscape\n' +
            '○ Architectural sketch\n' +
            '○ Event sketch\n' +
            '○ Exterior sketch\n' +
            '○ and others\n\n' +
            'Sketch is done in exact proportions, details and volume according to the technical task. I provide realistic style in my work and take care of all details and textures.\n' +
            'In general a Sketch is a great alternative to 3Ds Max. For example, a sketch is more cost-effective and faster way of visualization than 3D Max. ' +
            'All actual prices you can find on my website. Deadline is around 2-4 days and discount is 10% if you order more than 3 sketches.\n' +
            'If you have an idea I can help to turn it into a visual vivid project.',
        path: '/blog/aboutSketch',
        prevPath: '',
        nextPath: '/blog/sketchTypes'
    },
    {
        img: BlogImg2,
        title: 'Sketch types',
        description: 'I would like to tell you about what types of sketches I can draw for your projects.\n' +
            'There are several types:\n' +
            '○ Interior sketch (living room, kitchen, bedroom, bathroom and etc.)\n' +
            '○ Landscape (exterior and master plan)\n' +
            '○ 2d and 3d floor plans for interior, landscape and commercial projects like restaurants, office and others\n' +
            '○ Architectural sketch (facades, exterior, buildings and etc.)\n' +
            '○ Exterior sketch\n' +
            '○ Event sketch for weddings, celebrations, parties\n\n' +
            'All actual prices you can find on my website. Deadline is around 2-4 days and discount is 10% if you order more than 3 sketches.\n' +
            'I draw sketches for:\n' +
            '- interior designers and interior studios \n' +
            '- interior decorators\n' +
            '- landscape designers\n' +
            '- event agency’s\n' +
            '- real estate companies \n' +
            '- architects\n',
        path: '/blog/sketchTypes',
        prevPath: '/blog/aboutSketch',
        nextPath: '/blog/sketchForDesigners'
    },
    {
        img: BlogImg3,
        title: 'A sketch for designers',
        description: 'How designers can use a sketch in their work?\n ' +
            'I would like to share my personal experience as an interior designer.\n\n' +
            '➡ Sketch visualization before 3dsMax. \n' +
            ' It can really helps avoid a lot of edits in 3dsMax.\n\n' +
            '➡ Sketch visualization instead of 3dsMax. \n' +
            'Creative and vivid way to present project to client in right proportions and volume with wow-effects. By the way it is cheaper and faster!\n\n' +
            '➡ Sketches for architects, decorators, landscape designers, event planners\n\n' +
            '➡ Sketch as a very quick way to literally sketch out your idea for client in the moment',
        path: '/blog/sketchForDesigners',
        prevPath: '/blog/sketchTypes',
        nextPath: '/blog/editsTypes'
    },
    {
        img: BlogImg4,
        title: 'Edits types',
        description: 'I often hear that editing in sketch is difficult. Let’s clarify.\n ' +
            'First of all, drawing in Procreate app gives me the big advantages! And it is a work layer by layer. Because of this I can quick and easy to edit the finished sketch.\n' +
            'I wanna show you that if you will commission a sketch from me, then editing is convenient.\n' +
            'Free edits:\n' +
            '- Color corrections of furniture, walls, floor, ceilings\n' +
            '- Add/remove décor, plants\n' +
            '- Add/remove shades, lights\n' +
            '- Lights corrections\n\n' +
            'You can make free edits within 7 days from the date of receipt of the finished sketch. The free edits can be up to 20% of the total volume of the sketch.\n' +
            'Paid edits:\n' +
            '- Add or change of furniture\n' +
            '- New perspective of furniture\n' +
            '- After 7 days from the date of receipt of the finished sketch\n' +
            '- If edits more than 20% of the total volume of the sketch\n' +
            '- The price depends on volume\n\n' +
            'Usually edits take 1 day.',
        path: '/blog/editsTypes',
        prevPath: '/blog/sketchForDesigners',
        nextPath: '/blog/japandiStyle'
    },
    {
        img: BlogImg7,
        title: 'About Japandi style',
        description: 'Less is more! Recently I have worked on new project in Japandi style. I was really impressed of the result and I wanna share with you of sketch visualization and tell about key pieces of this style.\n' +
            'Japandi style is the must-have interiors trend that is a hybrid of Japanese and Scandinavian aesthetics.\n' +
            'The look is minimal, functional, warm and calming; with touches of wabi-sabi enthused imperfection.\n' +
            'How to achieve Japandi style in your home.\n\n' +
            '➡ Keep in clean\n' +
            'Minimalism is essential to a Japandi styled room. Choose furniture with pared- down lines and stay clear of designs with lots of ornamentation – the cleaner the better.\n\n' +
            '➡ Natural colors\n' +
            'Work with natural tones like blues, greens, plums and browns. Black is also a favorite in Japandi styled rooms.\n\n' +
            '➡ Accessories with care\n' +
            'The philosophies behind both nations’ design languages call for accessories that prioritise functionality over decoration.\n' +
            'Think unglazed ceramic tableware, vases and a few books.\n' +
            'Lastly, combine Scandi’s homely comforts with Japanese imperfection through contrasting fabrics.\n\n' +
            'Hope it was interesting and useful.',
        path: '/blog/japandiStyle',
        prevPath: '/blog/editsTypes',
        nextPath: '/blog/procreateTips'
    },
    {
        img: BlogImg5,
        title: 'Procreate tips',
        description: 'My 3 tips for beginner architectural artists drawing interior and exterior sketches in the Procreate app:\n' +
            '1) Use Layers: Procreate has a great layer system that allows you to work on different parts of your sketch without affecting other parts. For example, you can have one layer for your walls, another for your furniture, and another for your shading. This makes it easier to make changes and adjust your sketch as you go.\n\n' +
            '2) Pay attention to Proportions: When drawing architecture, it\'s important to pay close attention to proportions to ensure that everything looks correct and realistic. Use reference photos or measurements to ensure that your sketch is accurate.\n\n' +
            '3) Experiment with Textures and Brushes: Procreate offers a variety of textures and brushes that can be used to add depth and texture to your sketches. Experiment with different brushes to see which ones work best for your particular style, and don\'t be afraid to layer different textures to achieve the desired effect.',
        path: '/blog/procreateTips',
        prevPath: '/blog/japandiStyle',
        nextPath: '/blog/architecturalSketch'
    },
    {
        img: BlogImg8,
        title: 'Architectural sketch',
        description: 'An architectural sketch can be a powerful tool for architects in their work.\n' +
            '1) First of all, through sketching, architects can visualize different design solutions and make quick adjustments to refine the design. Sketching can also help architects to communicate their ideas to clients and team members more effectively.\n' +
            '2) Architectural sketches are a universal language that can be understood by anyone: your clients or team members.\n' +
            '3) Sketches can help architects to identify and quickly solve design problems. Because editing in Procreate app is fast and easy.\n' +
            '4) Sketches can also be used to create a visual diary of the project, which can be valuable for future reference.\n\n' +
            'In summary, architectural sketches are an essential tool for architects. Sketching allows architects to quickly explore design ideas, communicate their ideas to others, solve design problems, and document the design process.',
        path: '/blog/architecturalSketch',
        prevPath: '/blog/procreateTips',
        nextPath: '/blog/popularQuestion'
    },
    {
        img: BlogImg6,
        title: 'The most popular question',
        description: 'Do you have some master classes or drawing courses in Procreate? It’s one of the most frequently asked questions.\n' +
            'I really appreciate the high assessment of my work.\n' +
            'At the moment I don’t have any master classes, but I draw for commissions and can help you to turn your idea into a unique sketch project! \n\n' +
            'What we need to do?\n' +
            'From you - idea and technical task\n' +
            'From me - vivid sketch accurately in time, which will not leave indifferent to your customers and colleagues.\n',
        path: '/blog/popularQuestion',
        prevPath: '/blog/architecturalSketch',
        nextPath: ''
    },
]

export const priceData = [
    {
        img: PriceOverall,
    },
    {
        img: Price2D,
    },
    {
        img: Price2DWithLand,
    },
    {
        img: Price3D,
    },
    {
        img: PriceInteriorModern,
    },
    {
        img: PriceInteriorClassic,
    },
    {
        img: PriceEvent,
    },
    {
        img: PriceArchitect,
    },
    {
        img: PriceLandscape,
    },
    {
        img: PriceCommercial,
    },
    {
        img: PriceNightView,
    },
]